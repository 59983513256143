import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

// Bootstrap
import 'bootstrap';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

// Router & Store add
import router from './router'
// vue-resources
//import VueResource from 'vue-resource'
// vue-moment
//import VueMoment from 'vue-moment'
// store
import { store } from './store'

// vue-i18n
import { createI18n } from 'vue-i18n';
 
import { defaultLocale, localeOptions } from '@/constants/config'
// Notification Component Add
import Notifications from '@kyvg/vue3-notification'
// Breadcrumb Component Add
import Breadcrumb from '@/components/Common/Breadcrumb'
// RefreshButton Component Add
import RefreshButton from '@/components/Common/RefreshButton'
// Colxx Component Add
import Colxx from '@/components/Common/Colxx'
// Perfect Scrollbar Add
// import vuePerfectScrollbar from 'vue-perfect-scrollbar'
import contentmenu from 'v-contextmenu'
//import lineClamp from 'vue-line-clamp'
import VCalendar from 'v-calendar'
//import 'v-calendar/lib/v-calendar.min.css'
import VueScrollTo from 'vue-scrollto'
import VueSignature from 'vue-signature-pad';
//moment
import moment from 'moment';
import 'moment/locale/nl'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser, faClock, faEnvelope, faPlusCircle, faSyncAlt, faSort, faFilter, faSearch, faTable, faPencilAlt, faTrashAlt, faPlusSquare, faTimesCircle, faExclamationCircle, faCheckCircle, faToggleOff, faEye, faEyeSlash, faToggleOn, faThumbsUp, faThumbsDown, faArrowRight, faArrowLeft, faUnlock, faMusic, faUserPlus, faUserTimes, faCheck, faPaperPlane, faCopy, faQrcode, faClipboard, faSpinner, faTrash, faSave, faBarcode, faKey, faArrowUp, faArrowDown, faAngleDown, faAngleUp, faVoteYea, faTimes, faClose, faStop, faPlus, faArrowRightFromBracket, faArrowRightToBracket, faCartShopping} from '@fortawesome/free-solid-svg-icons'
// swal
import VueSweetalert2 from 'vue-sweetalert2';

// sba-crud-table
import SbaCrudTable from 'sba-crud-table-v3';

//jquery
import $ from 'jquery'

library.add(faUser, faClock, faEnvelope, faPlusCircle, faSyncAlt, faSort, faFilter, faSearch, faEye, faEyeSlash, faTable, faPencilAlt, faTrashAlt, faPlusSquare, faTimesCircle, faExclamationCircle, faCheckCircle, faToggleOff, faToggleOn, faThumbsUp, faThumbsDown, faArrowRight, faArrowLeft, faUnlock, faMusic, faUserPlus, faUserTimes, faCheck, faPaperPlane, faCopy, faQrcode, faClipboard, faSpinner, faTrash, faSave, faBarcode, faKey, faArrowDown, faAngleDown, faAngleUp, faVoteYea, faTimes, faClose, faStop, faPlus, faArrowRightFromBracket, faArrowRightToBracket, faCartShopping), 
app.component('font-awesome-icon', FontAwesomeIcon)

//general functionality
import { functions } from '@/mixins/functions.js';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

app.component("v-select", vSelect);

// vue-signature-pad
app.use(VueSignature);

// swal
app.use(VueSweetalert2);

// SbaCrudTable
app.component('SbaCrudTable', SbaCrudTable);

var messages = { }
const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale
//set moment locale
moment.locale(locale)
// vue-moment
app.use(moment);

const i18n = createI18n({
	locale: locale,
	fallbackLocale: 'en',
	messages,
	missing: (locale, key, instance, type) => {
		const data = {
			"locale": locale,
			"key": key,
		};
		functions.methods.req('POST', '/message/addMissing', data).then((response) => {
			if (response.status) {
				console.warn("Missing message with key: " + key + " & language: " + locale + " was added to the database.");
			} 
		});
		return key;
	},
});
app.use(i18n)

//get messages from backend
functions.methods.req('GET', '/message/getWithLanguage').then((response) => {
	if (response.status) {
		for (let locale in response.data) {
			i18n.global.setLocaleMessage(locale.toLowerCase(), response.data[locale]);
		}
	} 
});

app.use(Notifications)
app.component('piaf-breadcrumb', Breadcrumb)
app.component('b-refresh-button', RefreshButton)
app.component('b-colxx', Colxx)
// app.component('vue-perfect-scrollbar', vuePerfectScrollbar)
app.use(require('vue-shortkey'))
app.use(contentmenu)
//app.use(lineClamp, { /* plugin options */})
app.use(VCalendar, {
  firstDayOfWeek: 2, // ...other defaults,
  formats: {
    title: 'MMM YY',
    weekdays: 'WW',
    navMonths: 'MMMM',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'L'
  },
  datePickerShowDayPopover: false,
  popoverExpanded: true,
  popoverDirection: 'bottom'
})
app.use(VueScrollTo)

app.mixin(functions);

app.use(router);

app.use(store);

app.mount('#app');

import './assets/css/vendor/bootstrap.min.css'
import './assets/css/vendor/bootstrap.rtl.only.min.css'
import { defaultColor } from '@/constants/config'
/*  if you want use single color theme

- ColorSwitcher component remove in 'src/App.vue' file.
- Remove multicolor section in current file
- Uncomment the two lines below

import "./assets/css/sass/themes/sba.light.orange.scss";
import "./main";
*/

/* if you want single color remove this section:multicolor */
var color = defaultColor

let render = () => {
    import('./assets/css/sass/themes/sba.' + color + '.scss').then(x => require('./main'))
}

render()
/* if you want single color remove this section:multicolor */

export default app;
